var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wizard-step-container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h4', {
    staticClass: "mb-5 font-weight-bold text-dark"
  }, [_vm._v(_vm._s(_vm.$t("Administator information")))])]), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    ref: "userValidator",
    attrs: {
      "name": _vm.$t('Email'),
      "vid": "mail",
      "rules": {
        required: true,
        email_unique: _vm.physicalPersonInfo.login_email
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "label": "".concat(_vm.$t('Email (this email will be used for login)'), "*"),
            "placeholder": _vm.$t('Please enter your email')
          },
          model: {
            value: _vm.emailComputed,
            callback: function callback($$v) {
              _vm.emailComputed = $$v;
            },
            expression: "emailComputed"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "mb-8"
  }, [_c('multi-telephones-input', {
    key: _vm.multiTelephonesInputKey,
    attrs: {
      "label": "".concat(_vm.$t('Telephone number')),
      "is-multi": false,
      "options": _vm.TELEPHONE_KINDS
    },
    model: {
      value: _vm.phoneComputed,
      callback: function callback($$v) {
        _vm.phoneComputed = $$v;
      },
      expression: "phoneComputed"
    }
  })], 1)]), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Title'),
      "rules": "required|max:100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-input', {
          attrs: {
            "value": _vm.physicalPersonInfo.title,
            "label": "".concat(_vm.$t('Title'), "*"),
            "placeholder": _vm.$t('Legal representative, owner...'),
            "error-messages": errors
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('update:title', $event);
            }
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Name'),
      "rules": "required|max:30"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-input', {
          attrs: {
            "value": _vm.physicalPersonInfo.name,
            "label": "".concat(_vm.$t('First name'), "*"),
            "error-messages": errors
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('update:name', $event);
            }
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Last name'),
      "vid": "surname",
      "rules": "required|max:30"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-input', {
          attrs: {
            "value": _vm.physicalPersonInfo.surname,
            "label": "".concat(_vm.$t('Last name'), "*"),
            "error-messages": errors
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('update:surname', $event);
            }
          }
        })];
      }
    }])
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }