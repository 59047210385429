var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('wizard-left', {
    ref: "adminCreateWizardForm",
    attrs: {
      "clickable-steps": false
    },
    scopedSlots: _vm._u([{
      key: "wizard-nav-left",
      fn: function fn() {
        return [_c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Administator'),
            "desc": _vm.$t('Add information for the administrator'),
            "icon": "General/User.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Focus'),
            "desc": _vm.$t('Add information regarding the focus'),
            "icon": "Map/Marker1.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Completed!'),
            "desc": _vm.$t('Review and Submit'),
            "icon": "General/Like.svg"
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref) {
        var currentStep = _ref.currentStep,
          goPrev = _ref.goPrev;
        return [_c('validation-observer', {
          ref: "mainValidationObserver",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var _vm$addresses, _vm$addresses$address, _vm$addresses$address2, _vm$addresses$address3;
              var handleSubmit = _ref2.handleSubmit;
              return [_c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('keep-alive', [currentStep === 1 ? _c('validation-observer', {
                ref: "validator-1"
              }, [_vm.adminUserType == 'physical-person' ? _c('div', [_c('AdministratorInfoForm', _vm._b({
                attrs: {
                  "company-info": _vm.companyInfo,
                  "physical-person-info": _vm.physicalPersonInfo
                },
                on: {
                  "updateUserPhone": _vm.handlePhoneUpdate,
                  "updateUserEmail": _vm.handleEmailUpdate
                }
              }, 'AdministratorInfoForm', _vm.physicalPersonInfo, false, true))], 1) : _c('div', [_c('AdministratorInfoForm', _vm._b({
                attrs: {
                  "physical-person-info": _vm.physicalPersonInfo,
                  "company-info": _vm.companyInfo
                },
                on: {
                  "updateUserPhone": _vm.handlePhoneUpdate,
                  "updateUserEmail": _vm.handleEmailUpdate
                }
              }, 'AdministratorInfoForm', _vm.physicalPersonInfo, false, true))], 1)]) : _vm._e()], 1)], 1), _c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [currentStep === 2 ? _c('validation-observer', {
                ref: "validator-2"
              }, [_c('div', {
                staticClass: "row"
              }, [_c('div', {
                staticClass: "col-12"
              }, [_c('h4', {
                staticClass: "mb-10 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Add focus information")))])]), _c('div', {
                staticClass: "col-12"
              }, [_vm.getIsCompany ? _c('div', [_c('validation-provider', {
                attrs: {
                  "rules": "required",
                  "name": _vm.$t('Company Name'),
                  "vid": "company name"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref3) {
                    var errors = _ref3.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "label": "".concat(_vm.$t('Company Name'), "*"),
                        "value": _vm.companyInfo.companyName,
                        "error-messages": errors
                      },
                      on: {
                        "input": _vm.handleCompanyNameUpdate
                      }
                    })];
                  }
                }], null, true)
              })], 1) : _vm._e()]), _c('div', {
                staticClass: "col-12"
              }, [_vm.getIsCompany ? _c('div', [_c('p', {
                staticClass: "mb-5 font-weight-bold"
              }, [_vm._v(" " + _vm._s(_vm.$t("Add VAT to focus")) + ": ")])]) : _c('div', [_c('p', {
                staticClass: "mb-5 font-weight-bold"
              }, [_vm._v(_vm._s(_vm.$t("Add VAT to physical person")) + ":")])])]), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Country'),
                  "rules": "required",
                  "vid": "country"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref4) {
                    var errors = _ref4.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "value": _vm.companyInfo.country,
                        "label": "".concat(_vm.$t('Country'), "*"),
                        "error-messages": errors,
                        "options": _vm.countriesSelect,
                        "searchable": ""
                      },
                      on: {
                        "input": _vm.onCountryInput
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Vat'),
                  "vid": "vat",
                  "rules": _vm.rules.vat
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref5) {
                    var errors = _ref5.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "value": _vm.companyInfo.vat,
                        "label": "".concat(_vm.$t('VAT'), "*"),
                        "error-messages": errors
                      },
                      on: {
                        "input": _vm.handleVatUpdate
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_vm.getIsCompany ? _c('div', [_c('p', {
                staticClass: "mt-6 mb-5 font-weight-bold"
              }, [_vm._v(" " + _vm._s(_vm.$t("Add address to focus")) + ": ")])]) : _c('div', [_c('p', {
                staticClass: "mt-6 mb-5 font-weight-bold"
              }, [_vm._v(_vm._s(_vm.$t("Add address to physical person")) + ":")])])]), _c('div', {
                staticClass: "col-9"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "address_kind"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref6) {
                    var errors = _ref6.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": _vm.$t('Kind'),
                        "options": _vm.selectableKinds,
                        "error-messagess": errors
                      },
                      model: {
                        value: _vm.addresses.kind,
                        callback: function callback($$v) {
                          _vm.$set(_vm.addresses, "kind", $$v);
                        },
                        expression: "addresses.kind"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-3"
              }, [_c('div', {
                staticClass: "form-group mb-0 mt-12"
              }, [_c('div', {
                staticClass: "d-flex align-items-center justify-content-end"
              }, [_c('label', {
                staticClass: "col-form-label"
              }, [_vm._v(_vm._s(_vm.$t('Main address')))]), _c('div', {
                staticClass: "ml-3"
              }, [_c('span', {
                staticClass: "switch switch-sm switch-icon"
              }, [_c('label', [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.addresses.main,
                  expression: "addresses.main"
                }],
                attrs: {
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.addresses.main) ? _vm._i(_vm.addresses.main, null) > -1 : _vm.addresses.main
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.addresses.main,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.addresses, "main", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.addresses, "main", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.addresses, "main", $$c);
                    }
                  }
                }
              }), _c('span')])])])])])]), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "address_address",
                  "rules": {
                    required: true
                  }
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref7) {
                    var errors = _ref7.errors;
                    return [_c('AddressInput', {
                      attrs: {
                        "label": _vm.$t('Address'),
                        "active-step": currentStep === 3,
                        "error-messages": errors,
                        "address-center": _vm.addressCenter,
                        "manual-address": _vm.addresses.manual_address
                      },
                      on: {
                        "change-manual-address": _vm.changeManualAddress,
                        "update-composed-address": _vm.updateComposedAddress
                      },
                      model: {
                        value: _vm.addresses.address,
                        callback: function callback($$v) {
                          _vm.$set(_vm.addresses, "address", $$v);
                        },
                        expression: "addresses.address"
                      }
                    })];
                  }
                }], null, true)
              })], 1)])]) : _vm._e()], 1), currentStep === 3 ? _c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Confirm information to send invite")) + ":")]), _c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Administrator information")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_vm.physicalPersonInfo.title ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("Title")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.title))])]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.$t("Name")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.name))])]), _c('div', [_vm._v(" " + _vm._s(_vm.$t("Last name")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.surname))])]), _vm.telephoneNumbers.length > 0 ? _c('div', _vm._l(_vm.telephoneNumbers, function (phone) {
                return _c('div', {
                  key: phone.number,
                  staticClass: "text-dark-50 line-height-lg"
                }, [_vm._v(" " + _vm._s(_vm.TELEPHONE_KINDS.find(function (el) {
                  return el.value == phone.kind;
                }).text) + ": " + _vm._s(phone.number) + " ")]);
              }), 0) : _vm._e(), _vm.physicalPersonInfo.login_email ? _c('div', [_c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('span', [_vm._v(_vm._s(_vm.$t("Email")) + ": " + _vm._s(_vm.physicalPersonInfo.login_email))])])]) : _vm._e()]), _c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Focus Information")))]), _vm.adminUserType == 'company' ? _c('div', [_c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_vm._v(" " + _vm._s(_vm.$t("Name")) + ": " + _vm._s(_vm.companyInfo.companyName) + " ")])]) : _vm._e(), _vm.companyInfo.vat != null ? _c('div', [_c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_vm._v(" " + _vm._s(_vm.$t("Vat")) + ": " + _vm._s(_vm.companyInfo.vat) + " ")])]) : _vm._e(), (_vm$addresses = _vm.addresses) !== null && _vm$addresses !== void 0 && (_vm$addresses$address = _vm$addresses.address) !== null && _vm$addresses$address !== void 0 && _vm$addresses$address.formatted_address ? _c('div', [_c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_vm._v(" " + _vm._s(_vm.$t("Address")) + ": "), _vm.addresses.manual_address ? _c('span', [_vm._v(_vm._s(_vm.addresses.composed_address.formatted))]) : _c('span', [_vm._v(_vm._s((_vm$addresses$address2 = (_vm$addresses$address3 = _vm.addresses.address) === null || _vm$addresses$address3 === void 0 ? void 0 : _vm$addresses$address3.formatted_address) !== null && _vm$addresses$address2 !== void 0 ? _vm$addresses$address2 : "No address found"))]), _vm.addresses.description ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("Description")) + ": "), _c('span', [_vm._v(_vm._s(_vm.addresses.description))])]) : _vm._e()])]) : _vm._e()]) : _vm._e(), _c('wizard-actions-left', [_c('div', {
                staticClass: "mr-2"
              }, [_c('wizard-prev-btn-left', {
                on: {
                  "click": goPrev
                }
              })], 1), _c('div', [_c('wizard-submit-btn-left', {
                attrs: {
                  "label": _vm.$t('Save as Confirmed')
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }), _c('wizard-next-btn-left', {
                on: {
                  "click": _vm.handleNext
                }
              })], 1)])];
            }
          }], null, true)
        })];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }