<template>
  <wizard-left ref="adminCreateWizardForm" :clickable-steps="false">
    <template #wizard-nav-left>
      <wizard-nav-step-left :title="$t('Administator')" :desc="$t('Add information for the administrator')"
        icon="General/User.svg"></wizard-nav-step-left>
      <wizard-nav-step-left :title="$t('Focus')" :desc="$t('Add information regarding the focus')"
        icon="Map/Marker1.svg"></wizard-nav-step-left>
      <wizard-nav-step-left :title="$t('Completed!')" :desc="$t('Review and Submit')" icon="General/Like.svg" />
    </template>

    <template #default="{ currentStep, goPrev }">
      <validation-observer ref="mainValidationObserver" v-slot="{ handleSubmit }">
        <wizard-step-left class="custom-wizard-step">
          <keep-alive>
            <validation-observer v-if="currentStep === 1" ref="validator-1">
              <div v-if="adminUserType == 'physical-person'">
                <AdministratorInfoForm v-bind.sync="physicalPersonInfo" :company-info="companyInfo"
                  :physical-person-info="physicalPersonInfo" @updateUserPhone="handlePhoneUpdate"
                  @updateUserEmail="handleEmailUpdate"></AdministratorInfoForm>
              </div>
              <div v-else>
                <AdministratorInfoForm v-bind.sync="physicalPersonInfo" :physical-person-info="physicalPersonInfo"
                  :company-info="companyInfo" @updateUserPhone="handlePhoneUpdate" @updateUserEmail="handleEmailUpdate">
                </AdministratorInfoForm>
              </div>
            </validation-observer>
          </keep-alive>
        </wizard-step-left>

        <wizard-step-left class="custom-wizard-step">
          <validation-observer v-if="currentStep === 2" ref="validator-2">
            <div class="row">
              <div class="col-12">
                <h4 class="mb-10 font-weight-bold text-dark">{{ $t("Add focus information") }}</h4>
              </div>
              <div class="col-12">
                <div v-if="getIsCompany">
                  <validation-provider v-slot="{ errors }" rules="required" :name="$t('Company Name')" vid="company name">
                    <euro-input :label="`${$t('Company Name')}*`" :value="companyInfo.companyName"
                      :error-messages="errors" @input="handleCompanyNameUpdate"></euro-input>
                  </validation-provider>
                </div>
              </div>
              <div class="col-12">
                <div v-if="getIsCompany">
                  <p class="mb-5 font-weight-bold"> {{ $t("Add VAT to focus") }}: </p>
                </div>
                <div v-else>
                  <p class="mb-5 font-weight-bold">{{ $t("Add VAT to physical person") }}:</p>
                </div>
              </div>
              <div class="col-6">
                <validation-provider v-slot="{ errors }" :name="$t('Country')" rules="required" vid="country">
                  <euro-select :value="companyInfo.country" :label="`${$t('Country')}*`" :error-messages="errors"
                    :options="countriesSelect" searchable @input="onCountryInput"></euro-select>
                </validation-provider>
              </div>
              <div class="col-6">
                <validation-provider v-slot="{ errors }" :name="$t('Vat')" vid="vat" :rules="rules.vat">
                  <euro-input :value="companyInfo.vat" :label="`${$t('VAT')}*`" :error-messages="errors"
                    @input="handleVatUpdate"></euro-input>
                </validation-provider>
              </div>
              <div class="col-12">
                <div v-if="getIsCompany">
                  <p class="mt-6 mb-5 font-weight-bold"> {{ $t("Add address to focus") }}: </p>
                </div>
                <div v-else>
                  <p class="mt-6 mb-5 font-weight-bold">{{ $t("Add address to physical person") }}:</p>
                </div>
              </div>
              <div class="col-9">
                <validation-provider v-slot="{ errors }" vid="address_kind">
                  <euro-select v-model="addresses.kind" :label="$t('Kind')" :options="selectableKinds"
                    :error-messagess="errors"></euro-select>
                </validation-provider>
              </div>
              <div class="col-3">
                <div class="form-group mb-0 mt-12">
                  <div class="d-flex align-items-center justify-content-end">
                    <label class="col-form-label">{{ $t('Main address') }}</label>
                    <div class="ml-3">
                      <span class="switch switch-sm switch-icon">
                        <label>
                          <input v-model="addresses.main" type="checkbox" />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" vid="address_address" :rules="{ required: true }">
                  <AddressInput v-model="addresses.address" :label="$t('Address')" :active-step="currentStep === 3"
                    :error-messages="errors" :address-center="addressCenter" :manual-address="addresses.manual_address"
                    @change-manual-address="changeManualAddress" @update-composed-address="updateComposedAddress">
                  </AddressInput>
                </validation-provider>
              </div>
            </div>
          </validation-observer>
        </wizard-step-left>

        <wizard-step-left v-if="currentStep === 3" class="custom-wizard-step">
          <h6 class="font-weight-bolder mb-3">{{ $t("Confirm information to send invite") }}:</h6>
          <div class="separator separator-dashed my-5"></div>
          <h6 class="font-weight-bolder mb-3">{{ $t("Administrator information") }}</h6>
          <div class="text-dark-50 line-height-lg">
            <div v-if="physicalPersonInfo.title">
              {{ $t("Title") }}: <span>{{ physicalPersonInfo.title }}</span>
            </div>
            <div>
              {{ $t("Name") }}: <span>{{ physicalPersonInfo.name }}</span>
            </div>
            <div>
              {{ $t("Last name") }}:
              <span>{{ physicalPersonInfo.surname }}</span>
            </div>
            <div v-if="telephoneNumbers.length > 0">
              <div v-for="phone in telephoneNumbers" :key="phone.number" class="text-dark-50 line-height-lg">
                {{ TELEPHONE_KINDS.find(el => el.value == phone.kind).text }}: {{ phone.number }}
              </div>
            </div>
            <div v-if="physicalPersonInfo.login_email">
              <div class="text-dark-50 line-height-lg">
                <span>{{ $t("Email") }}: {{ physicalPersonInfo.login_email }}</span>
              </div>
            </div>
          </div>
          <div class="separator separator-dashed my-5"></div>
          <h6 class="font-weight-bolder mb-3">{{ $t("Focus Information") }}</h6>
          <div v-if="adminUserType == 'company'">
            <div class="text-dark-50 line-height-lg">
              {{ $t("Name") }}: {{ companyInfo.companyName }}
            </div>
          </div>
          <div v-if="companyInfo.vat != null">
            <div class="text-dark-50 line-height-lg">
              {{ $t("Vat") }}: {{ companyInfo.vat }}
            </div>
          </div>
          <div v-if="addresses?.address?.formatted_address">
            <div class="text-dark-50 line-height-lg">
              {{ $t("Address") }}:
              <span v-if="addresses.manual_address">{{ addresses.composed_address.formatted }}</span>
              <span v-else>{{ addresses.address?.formatted_address ?? "No address found" }}</span>
              <div v-if="addresses.description">
                {{ $t("Description") }}: <span>{{ addresses.description }}</span>
              </div>
            </div>
          </div>
        </wizard-step-left>

        <wizard-actions-left>
          <div class="mr-2">
            <wizard-prev-btn-left @click="goPrev"></wizard-prev-btn-left>
          </div>
          <div>
            <wizard-submit-btn-left :label="$t('Save as Confirmed')" @click="handleSubmit(submit)">
            </wizard-submit-btn-left>
            <wizard-next-btn-left @click="handleNext"></wizard-next-btn-left>
          </div>
        </wizard-actions-left>
      </validation-observer>
    </template>
  </wizard-left>
</template>

<script>
import PhysicalPersonAddressService from "@/core/services/physical-person/physical-person-address.service";
import AdministratorInfoForm from "@/view/components/forms/invitations/AdministratorInfoForm.vue"
import PhysicalPersonEmailService from "@/core/services/physical-person/physical-person-email.service";
import PhysicalPersonSocialService from "@/core/services/physical-person/physical-person-social.service";
import CompanyAddressService from "@/core/services/company/company-address.service";
import PhysicalPersonTelephoneService from "@/core/services/physical-person/physical-person-telephone.service";
import icons from "@/core/config/icons";
import Swal from "sweetalert2";
import { mapState } from "vuex";
import AddressService from "@/core/services/address.service.js"

export default {
  components: {
    AdministratorInfoForm,
  },

  props: {
    errorMessages: {
      type: Object,
      default: null,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    adminUserType: {
      type: String,
      default: null,
    },
    inviteType: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    icons,
    physicalPersonInfo: {
      id: null,
      login_email: "",
      name: "",
      surname: "",
      avatar_tus: null,
      date_birth: null,
      place_birth: null,
      gender: "MA",
      citizenship_set: [],
      telcontact_set: [],
      socialcontacts_set: [],
      is_employee: false,
      title: ""
    },
    companyInfo: {
      id: null,
      companyName: null,
      country: null,
      vat: null
    },
    addresses: {
      address: null,
      address_id: null,
      description: null,
      kind: "0",
      main: true,
      manual_address: false,
      composed_address: null
    },
    EMAIL_KINDS: [],
    SOCIAL_KINDS: [],
    TELEPHONE_KINDS: [],
    multiTelephonesInputKey: 0,
    addressKinds: [],
    addressKindsCompany: [],
    addressCenter: null
  }),

  computed: {
    ...mapState("constants", ["countries"]),
    rules() {
      return {
        country: {
          required: false,
        },
        vat: {
          required: true,
          vat: [this.companyInfo.country, true]
        },
      };
    },
    countriesSelect() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    selectableKinds() {
      if (this.adminUserType == 'company') {
        return this.addressKindsCompany;
      }
      return this.addressKinds;
    },

    computedMsg() {
      let msg = this.$t("Contact person creation failed");
      for (const el in this.observerErrors) {
        if (this.observerErrors[el].length) {
          msg = this.observerErrors[el][0];
          break;
        }
      }
      return msg;
    },
    getIsCompany() {
      return this.$route.params.type == "company" ? true : false;
    },

    telephoneNumbers() {
      return this.physicalPersonInfo.telcontact_set.filter(el => el.number != '')
    }
  },

  async mounted() {
    PhysicalPersonEmailService.getKinds()
      .then((res) => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(email => (this.EMAIL_KINDS = email))
      .catch((err) => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load email kinds. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      });
    PhysicalPersonAddressService.getKinds()
      .then((res) => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(address => (this.addressKinds = address))
      .catch((err) => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load address kinds. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      });
    CompanyAddressService.getKinds()
      .then((res) => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(address => (this.addressKindsCompany = address))
      .catch((err) => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load address kinds. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      });
    PhysicalPersonSocialService.getKinds()
      .then((res) => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(social => (this.SOCIAL_KINDS = social))
      .catch((err) => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load address kinds. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      });
    PhysicalPersonTelephoneService.getKinds()
      .then(res => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(telKinds => (this.TELEPHONE_KINDS = telKinds))
      .catch(err => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load telephone kinds. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      })
  },

  methods: {
    handleCompanyNameUpdate(val) {
      this.companyInfo.companyName = val;
    },

    handlePhoneUpdate(val) {
      if (val[0]) this.physicalPersonInfo.telcontact_set = [val[0]];
      else this.physicalPersonInfo.telcontact_set = [];
    },
    handleEmailUpdate(val) {
      this.physicalPersonInfo.login_email = val;
    },
    async handleVatUpdate(val) {
      this.companyInfo.vat = val;
    },
    async addressRequest() {
      const convertedAddresses = await AddressService.getOrCreateAddress([this.addresses]);
      const address = { ...this.addresses, address_id: convertedAddresses[0].id }
      delete address.address;
      delete address.composed_address;
      delete address.manual_address;

      this.addresses = [address];
    },
    async onCountryInput(country) {
      if (!country) {
        this.companyInfo.country = country;
        return;
      }

      this.companyInfo.country = country;
    },

    async submit() {
      await this.addressRequest();
      const company = this.adminUserType == 'company' ? 0 : 1; // kind company is 0
      const body = {
        inviteType: this.inviteType,
        email: this.physicalPersonInfo.login_email,
        physicalPerson: {
          id: this.physicalPersonInfo.id,
          name: this.physicalPersonInfo.name,
          surname: this.physicalPersonInfo.surname,
          telcontact_set: this.telephoneNumbers,
        },
        userID: this.physicalPersonInfo.id,
        title: this.physicalPersonInfo.title
      };
      if (!company) {
        body.physicalPerson.physicalpersonaddress_set = this.addresses;
      }
      body.company = {
        name: this.companyInfo.companyName ? this.companyInfo.companyName : this.physicalPersonInfo.name + " " + this.physicalPersonInfo.surname,
        kind: company,
        companyaddress_set: this.addresses,
        vat: this.vat
      }
      this.$emit("submit", body);
    },

    async handleNext() {
      const validator = this.$refs[`validator-${this.$refs.adminCreateWizardForm.getStep()}`];
      if (validator) {
        await validator.reset();
        const isValid = await validator.validate();
        if (!isValid) {
          if (this.$refs.adminCreateWizardForm.getStep() === 2) {
            if (this.addresses.address == null || this.addresses.hasOwnProperty('formatted_address') && this.addresses.formatted_address == null) {
              await Swal.fire({
                title: this.$t("Caution"),
                text: this.$t("You must add a location in the address field"),
                icon: "warning"
              });
              return;
            }
          } else {
            this.$nextTick();
            return;
          }
        }
      }
      this.$refs.mainValidationObserver.handleSubmit(this.$refs.adminCreateWizardForm.goNext());
    },
    changeManualAddress(val) {
      this.addresses.manual_address = val
    },
    updateComposedAddress(val) {
      this.addresses.composed_address = val;
    }
  },
};
</script>

<style scoped>
.wizard-step-container {
  max-width: 600px;
  margin: 0 auto;
}
</style>
