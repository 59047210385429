<template>
  <b-card>
    <b-card-title> {{ $t("Create new Atlasposting focus") }} </b-card-title>
    <AdminCreateWizard :is-submitting="isSubmitting" :invite-type="getInviteType" :admin-user-type="getAdminUserType"
      @submit="submitRootUser"></AdminCreateWizard>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AdminCreateWizard from "@/view/components/wizards/AdminCreateWizard.vue";
import InvitationService from "@/core/services/invitation/invitation.service";
import { backendErrorSwal, successSwal } from "@/core/helpers/swal";
import CompanyManagedCompaniesService from "@/core/services/company/company-managedcompanies.service";
import { mapActions } from "vuex";

export default {
  components: {
    AdminCreateWizard,
  },

  data: () => ({
    isSubmitting: false,
  }),

  computed: {
    getAdminUserType() {
      return this.$route.params.type;
    },
    getInviteType() {
      return "root_user";
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Administration") },
      { title: this.$t("Atlasposting Users"), route: { name: "administration-users" } },
      { title: this.$t("Create Focus") },
    ]);
  },

  methods: {
    ...mapActions('user', ['setUserManagedCompanies']),
    async submitRootUser(body) {
      this.isSubmitting = true;
      try {
        await InvitationService.createInvitation(body);
        await CompanyManagedCompaniesService.getAll().then((res) => {
          return this.setUserManagedCompanies(res.results);
        }).then(() => {
          successSwal(this.$t("Success"), this.$t("Mail sent"), "success");
          this.$router.push({ name: "administration-users-manage" });
        });
      } catch (err) {
        console.error(err);
        backendErrorSwal(err, "Unable to send the invitation");
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
</script>
