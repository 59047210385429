<template>
  <div class="wizard-step-container">
    <div class="row">
      <div class="col-12">
        <h4 class="mb-5 font-weight-bold text-dark">{{ $t("Administator information") }}</h4>
      </div>
      <div class="col-12">
        <validation-provider ref="userValidator" v-slot="{ errors }" :name="$t('Email')" vid="mail" :rules="{
          required: true,
          email_unique: physicalPersonInfo.login_email,
        }">
          <euro-input v-model="emailComputed" :error-messages="errors"
            :label="`${$t('Email (this email will be used for login)')}*`"
            :placeholder="$t('Please enter your email')"></euro-input>
        </validation-provider>
      </div>
      <div class="col-12">
        <div class="mb-8">
          <multi-telephones-input :key="multiTelephonesInputKey" v-model="phoneComputed"
            :label="`${$t('Telephone number')}`" :is-multi="false" :options="TELEPHONE_KINDS"></multi-telephones-input>
        </div>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Title')" rules="required|max:100">
          <euro-input :value="physicalPersonInfo.title" :label="`${$t('Title')}*`"
            :placeholder="$t('Legal representative, owner...')" :error-messages="errors"
            @input="$emit('update:title', $event)"></euro-input>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Name')" rules="required|max:30">
          <euro-input :value="physicalPersonInfo.name" :label="`${$t('First name')}*`" :error-messages="errors"
            @input="$emit('update:name', $event)"></euro-input>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Last name')" vid="surname" rules="required|max:30">
          <euro-input :value="physicalPersonInfo.surname" :label="`${$t('Last name')}*`" :error-messages="errors"
            @input="$emit('update:surname', $event)"></euro-input>
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PhysicalPersonDetailService from "@/core/services/physical-person/physical-person-detail.service";
import PhysicalPersonTelephoneService from "@/core/services/physical-person/physical-person-telephone.service";
import { mapGetters } from "vuex";
import DateService from "@/core/services/date.service";
import { mapState } from "vuex";

export default {
  components: {
  },
  props: {
    physicalPersonInfo: {
      type: Object,
      default: null
    },
    companyInfo: {
      type: Object,
      default: null
    }
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      DateService,
      maxDate: today,
      TELEPHONE_KINDS: [],
      GENDERS: [],
      multiTelephonesInputKey: 0,
      userEmail: "",
      nations: [],
    };
  },

  computed: {
    ...mapGetters("user", ["date_format", "dateFormat"]),
    ...mapState("constants", ["countries"]),

    citizenshipsComp: {
      get() {
        return this.physicalPersonInfo.citizenship_set.map(el => this.$t(el.country));
      },
      set(val) {
        this.physicalPersonInfo.citizenship_set = val.map(el => ({ country: el }));
      },
    },

    emailComputed: {
      get() {
        return this.physicalPersonInfo.login_email;
      },
      set(val) {
        this.$emit("updateUserEmail", val);
      },
    },
    phoneComputed: {
      get() {
        return this.physicalPersonInfo.telcontact_set;
      },
      set(val) {
        return this.$emit("updateUserPhone", val);
      },
    },
    stringMaxDate() {
      return moment(this.maxDate, true).format(this.date_format);
    },
    computedDisabledPhone() {
      return this.physicalPersonInfo.phone[0]?.number ?? "";
    },

    countriesSelectProps() {
      return {
        options: this.countries,
        "track-by": "iso",
        label: "name",
        multiple: true,
        "allow-empty": true,
        searchable: true,
      };
    },

    groupsSelectProps() {
      return {
        options: this.groupsChoices,
        "track-by": "id",
        label: "name",
        multiple: true,
        "allow-empty": true,
        searchable: true,
      };
    },
    getIsCompany() {
      return this.$route.params.type == "company" ? true : false;
    },
    countriesSelect() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    }
  },

  async mounted() {
    this.nations = this.countries;
    PhysicalPersonDetailService.getGenders()
      .then((res) => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(genKinds => (this.GENDERS = genKinds))
      .catch((err) => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load genders. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      })
    PhysicalPersonTelephoneService.getKinds()
      .then(res => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(telKinds => (this.TELEPHONE_KINDS = telKinds))
      .catch(err => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load telephone kinds. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      })
  },

  methods: {
    moment,
    handleDisabled(e) {
      if (this.userSetup.selected) e.stopPropagation();
    },
    updateMailValue(event) {
      this.$emit("update:mail", event);
    },
  },
};
</script>

<style scoped>
.wizard-step-container {
  max-width: 600px;
  margin: 0 auto;
}
</style>
